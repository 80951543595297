<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <khrs-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_virtual_logo.png' class='h-16 mb-12' />
      <div class='px-4 py-8 rounded' style='background-color: #FAFAFA;'>
        <div class='mb-4 flex flex-col lg:flex-row items-start lg:items-center'>
          <div 
            class='font-semibold w-36'
            :style='themeTextColorStyle'>
            Title
          </div>
          <div class=''>
            KHRS 2022: The 14th Annual Scientific Session of the Korean Heart Rhythm Society
          </div>
        </div>
        <div class='my-4 flex flex-col lg:flex-row items-start lg:items-center'>
          <div 
            class='font-semibold w-36'
            :style='themeTextColorStyle'>
            Date
          </div>
          <div class=''>
            June 24 (Fri) - 25 (Sat), 2022
          </div>
        </div>
        <div class='my-4 flex flex-col lg:flex-row items-start lg:items-center'>
          <div 
            class='font-semibold w-36'
            :style='themeTextColorStyle'>
            Host
          </div>
          <div class=''>
            Korean Heart Rhythm Society (KHRS)
          </div>
        </div>
        <div class='my-4 flex flex-col lg:flex-row items-start lg:items-center'>
          <div 
            class='font-semibold w-36'
            :style='themeTextColorStyle'>
            Language
          </div>
          <div class=''>
            Korean, English
            <sub class='block mt-1'><span :style='themeTextColorStyle'>*</span>The language of an individual session may vary.</sub>
          </div>
        </div>
      </div>
      <div class='py-12'>
        <h2 class='text-2xl font-semibold mt-4'>Session Categories</h2>
        <div class='flex flex-col lg:flex-row justify-between mt-8 gap-y-12 lg:gap-y-0'>
          <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0'>
            <h3 class='text-lg uppercase font-semibold text-gray-600'>Special Sessions</h3>
            <ul>
              <li class='py-0.5'>Cross Specialty</li>
              <li class='py-0.5'>New Research in Major Companies</li>
              <li class='py-0.5'>Policy & New Technology</li>
              <li class='py-0.5'>Special Lecture</li>
              <li class='py-0.5'>Syncope and Others</li>
              <li class='py-0.5'>YIA Competition</li>
            </ul>
          </div>
          <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0'>
            <h3 class='text-lg uppercase font-semibold text-gray-600'>Scientific Sessions</h3>
            <ul>
              <li class='py-0.5'>Atrial Fibrillation</li>
              <li class='py-0.5'>Basic</li>
              <li class='py-0.5'>CIED</li>
              <li class='py-0.5'>Joint Symposium with Overseas Society</li>
              <li class='py-0.5'>Noninvasive</li>
              <li class='py-0.5'>Oral/Poster</li>
              <li class='py-0.5'>Pediatric</li>
              <li class='py-0.5'>PSVT</li>
              <li class='py-0.5'>SCD</li>
              <li class='py-0.5'>Ventricular Tachycardia</li>
              
            </ul>
          </div>
          <div class='w-full lg:w-1/3 flex-grow-0 flex-shrink-0'>
            <h3 class='text-lg uppercase font-semibold text-gray-600'>Education Sessions</h3>
            <ul>
              <li class='py-0.5'>Allied Professional</li>
              <li class='py-0.5'>Arrhythmia Review Course</li>
              <li class='py-0.5'>EGM Tracing</li>
              <li class='py-0.5'>EKG Educational Course</li>
              <li class='py-0.5'>MR Education</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KhrsAboutPageNavigation from '@/components/khrs-virtual/KhrsAboutPageNavigation.vue'

export default {
  name: 'Overview',
  components: {
    KhrsAboutPageNavigation,
  },
  props: [

  ],
  computed: {
    ...mapGetters('events',[
      'themeTextColorStyle',
    ]),
  }
}
</script>
