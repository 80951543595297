<template>
  <div
    class='background-style text-white py-4 px-4 lg:py-16 lg:px-16'
    :style='`${backgroundStyle}`'>
    <h2 class='text-2xl capitalize font-semi pl-2'>About</h2>
    <ul class='text-xs lg:text-lg mt-4 about-page flex flex-row lg:flex-col'>
      <li class=''>
        <router-link :to='{ name: "Welcome" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png' class='standard-transition opacity-50'>
          <span>Greetings</span>
        </router-link>
      </li>
      <li class=''>
        <router-link :to='{ name: "Overview" }' class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png' class='standard-transition opacity-50'>
          <span>Overview</span>
        </router-link>
      </li>
      <li class=''>
        <router-link :to='{ name: "Speakers" }' class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png' class='standard-transition opacity-50'>
          <span>Faculty</span>
        </router-link>
      </li>
      <li class=''>
        <router-link :to='{ name: "ContactUs" }'  class='py-2 pl-2 pr-2 uppercase opacity-90 flex flex-col-reverse lg:flex-row items-center justify-end lg:justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png' class='standard-transition opacity-50'>
         <span>Contact Us</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KhrsAboutPageNavigation',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    }
  }
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}

.about-page .router-link-active {
  @apply font-bold;
}

.about-page img {
  @apply w-px;
}

.about-page .router-link-active img,
.about-page a:hover img {
  @apply opacity-100 w-6;
}
  
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}


</style>


